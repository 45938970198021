import '../../styles/base.css'
import './modal.css'
import wavingPikachu from './../../assets/images/pikachu.svg'
import tcglLogo from './../../assets/images/ptcgl-logo.png'
import uniteLogo from './../../assets/images/unite-logo.png'
import goLogo from './../../assets/images/go-logo.png'
import pocketLogo from './../../assets/images/pocket-logo.png'
import { useTranslation } from 'react-i18next'
import { RedemptionInstructions, GameType } from './../redemption-instructons/redemptionInstructions'
import { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface RedeemCodeInstructionsModalProps {
  setModal: Function
  modalRef: React.RefObject<HTMLDivElement>
}

function RedeemCodeInstructionsModal (props: RedeemCodeInstructionsModalProps) {
  const { t } = useTranslation()
  const { lettuceRewards } = useFlags()
  const eatClick = (e: any) => {
    e.stopPropagation()
  }

  useEffect(() => {
    // force into view
    props.modalRef?.current?.scrollIntoView({ block: 'start' })
    props.modalRef.current?.focus()
    // eslint-disable-next-line
  }, [props.modalRef])

  return (
    <div id='instructions-modal' className='modal h-5/6' onClick={eatClick} role="presentation">
      <div className='graphic'>
        <img className='w-3/4 h-1/6 m-auto' src={wavingPikachu} alt='waving pikachu' />
      </div>
      <div className='content overflow-auto'>
        <div className='h-full text-left'>
          <img className='w-1/2 h-1/6 object-scale-down m-auto' src={tcglLogo} alt={'Pokémon TCGL'} />
          <RedemptionInstructions gameType={GameType.TCGL} />
          <hr />
          <img className='w-1/2 h-1/6 object-scale-down m-auto' src={uniteLogo} alt={'Pokémon Unite'} />
          <RedemptionInstructions gameType={GameType.Unite} />
          <hr />
          <img className='w-1/2 h-1/6 object-scale-down m-auto' src={goLogo} alt={'Pokémon Go'} />
          <RedemptionInstructions gameType={GameType.Go} />
          { lettuceRewards &&
            <>
              <hr/>
              <img className='w-1/2 h-1/6 object-scale-down m-auto' src={pocketLogo} alt={'Pokémon Trading Card Game Pocket'} />
              <RedemptionInstructions gameType={GameType.TCGP} />
            </>
          }
        </div>
      </div>
      <div className='buttons'>
        <div
          className='button'
          onClick={() => props.setModal(undefined)}
          onKeyPress={() => props.setModal(undefined)}
          role='button'
          tabIndex={0}
        >
          {t('close', 'Close')}
        </div>
      </div>
    </div>
  )
}

export default RedeemCodeInstructionsModal
