import { TcglInstructions } from './tcgl'
import { UniteInstructions } from './unite'
import { GoInstructions } from './go'
import { PocketInstructions } from './pocket'

export enum GameType {
  None,
  TCGL,
  PokemonCenter,
  Unite,
  Go,
  TCGP,
}

interface RedemptionInstructionsProps {
  gameType: GameType
}

export const RedemptionInstructions = (_props: RedemptionInstructionsProps) => {
  let instructions
  switch (_props.gameType) {
    case GameType.TCGL:
      instructions = <TcglInstructions></TcglInstructions>
      break
    case GameType.TCGP:
      instructions = <PocketInstructions></PocketInstructions>
      break
    case GameType.Unite:
      instructions = <UniteInstructions></UniteInstructions>
      break
    case GameType.Go:
      instructions = <GoInstructions></GoInstructions>
      break
    default:
      instructions = null
      break
  }
  return instructions
}
