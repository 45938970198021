import { useLocation } from 'react-router-dom'

export const latamCountryCodes = [
  'ar', // argentina
  'bo', // bolivia
  'cl', // chile
  'co', // colombia
  'cr', // costa rica
  'do', // dominican republic
  'ec', // ecuador
  'sv', // el salvador
  'gt', // guatemala
  'hn', // honduras
  'mx', // mexico
  'ni', // nicaragua
  'pa', // panama
  'py', // paraguay
  'pe', // peru
  'uy', // uruguay
  've' // venezuela
]

export function formatDateAndTime (date: Date) {
  const result = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(new Date(date))
  return result
}

export function formatDate (date: Date) {
  const result = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(new Date(date))
  return result
}

export function useQueryString () {
  return new URLSearchParams(useLocation().search)
}
