import React, { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'

import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import './index.css'
import './main.css'
import App from './app'
import spinner from './assets/images/pokeball_gray.png'
import './utils/i18n'
import { apiBaseUri, ldClientId } from './utils/setup'
import reportWebVitals from './utils/reportWebVitals'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldClientId
  })
  ReactDOM.render(
    <LDProvider>
      <StrictMode>
        <Suspense fallback={<img className='spinner' src={spinner} alt='…' />}>
          <App />
        </Suspense>
      </StrictMode>
    </LDProvider>,
    document.getElementById('root')
  )
})()

datadogRum.init({
  applicationId: 'a5871a99-cdfd-49b5-802f-3d9d1bd75fc3',
  clientToken: 'pubb553d86d788013c704baa772f3b607a7',
  site: 'datadoghq.com',
  service: 'rewards-frontend',
  env: process.env.REACT_APP_STAGE,
  version: require('../package.json').version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    apiBaseUri || ''
  ]
})

datadogRum.startSessionReplayRecording()

datadogLogs.init({
  clientToken: 'pub549c790bfd95e830ce8dd5ab0f7f3fff',
  site: 'datadoghq.com',
  silentMultipleInit: true,
  forwardErrorsToLogs: true,
  sampleRate: 100
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
