import '../styles/footer.css'
import caruLogo from '../assets/images/caru.svg'
import { useTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'
import { getSupportLink, localizeLink, countryCodeReplacementString } from '@tpci/i18next-ext'

interface FooterProps {
  setModal: Function
}

function Footer (props: FooterProps) {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()
  const copyrightPokemon = <Trans
    t={t}
    i18nKey='copyright_pokemon'
    defaults='©{{curr_year}} Pokémon.'
    values={{ curr_year: currentYear }}
  />
  const copyrightNintendo = <Trans
    t={t}
    i18nKey='copyright_nintendo'
    defaults='©1995 - {{curr_year}} Nintendo/Creatures Inc./GAME FREAK inc. TM, ®Nintendo.'
    values={{ curr_year: currentYear }}
  />

  const PCOM_URL = (process.env.REACT_APP_STAGE === 'live' || process.env.REACT_APP_STAGE === undefined) ? 'https://www.pokemon.com' : `https://www.${process.env.REACT_APP_STAGE}.pokemon.com`
  const COMPANY_TERMS_OF_USE_LINK = `${PCOM_URL}/${countryCodeReplacementString}/terms-of-use/`
  const PRIVACY_NOTICE_LINK = `${PCOM_URL}/${countryCodeReplacementString}/privacy-notice/`
  const COOKIE_PAGE_LINK = `${PCOM_URL}/${countryCodeReplacementString}/cookie-page/`
  const LEGAL_PAGE_LINK = `${PCOM_URL}/${countryCodeReplacementString}/legal/`

  const openModal = (modal: string) => {
    props.setModal(modal)
  }

  return (
    <div className='legal-footer'>
      <div className='links-and-caru py-1 px-2.5 lg:px-[15vw] font-medium'>
        <div
          role='link'
          tabIndex={0}
          className='exit-link'
          onKeyPress={() => openModal('Caru')}
          onClick={() => openModal('Caru')}
        >
          <img alt='caru logo' src={caruLogo} />
        </div>
        <div className='links'>
          <a
            tabIndex={0}
            target='_blank'
            rel='noopener noreferrer'
            href={getSupportLink(i18n.language) + '/articles/16594985271316'}
          >
            {t('customer_service', 'Customer Service')}
          </a>
          <button
            tabIndex={0}
            role='link'
            onClick={() => openModal('TermsOfUse')}
            onKeyDown={() => openModal('TermsOfUse')}
          >
            {t('program_terms_of_use', 'Rewards Program Terms of Use')}
          </button>
          <a
            tabIndex={0}
            target='_blank'
            rel='noopener noreferrer'
            href={localizeLink(i18n.language, COMPANY_TERMS_OF_USE_LINK)}
          >
            {t('general_terms_of_use', 'General Terms of Use')}
          </a>
          <a
            tabIndex={0}
            target='_blank'
            rel='noopener noreferrer'
            href={localizeLink(i18n.language, PRIVACY_NOTICE_LINK)}
          >
            {t('privacy_notice', 'Privacy Notice')}
          </a>
          <a tabIndex={0} id='cookieLink' href={localizeLink(i18n.language, COOKIE_PAGE_LINK)}>
            {t('cookie_page', 'Cookie Page')}
          </a>
          <a tabIndex={0} href={localizeLink(i18n.language, LEGAL_PAGE_LINK)}>
            {t('legal_info', 'Legal Info')}
          </a>
          <button
            tabIndex={0}
            role='link'
            onClick={() => openModal('RedeemCodeInstructions')}
            onKeyDown={() => openModal('RedeemCodeInstructions')}
          >
            {t('redeem_code_instructions', 'Redeem Code Instructions')}
          </button>
        </div>
      </div>
      <div className='copyright'>{copyrightPokemon} {copyrightNintendo}</div>
    </div>
  )
}

export default Footer
