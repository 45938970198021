import './modal.css'
import TermsOfUseModal from './TermsOfUseModal'
import LeavingSiteModal from './LeavingSiteModal'
import RedeemCodeInstructionsModal from './RedeemCodeInstructionsModal'
import i18n from 'i18next'
import { localizeLink } from '@tpci/i18next-ext'
import { useRef } from 'react'

interface ModalProps extends JSX.IntrinsicAttributes {
  type: 'Caru' | 'TermsOfUse' | 'RedeemCodeInstructions' | undefined
  setModal: Function
}

function Modal (props: ModalProps) {
  const modalClass = props.type ? 'active' : 'inactive'
  const caruLink = localizeLink(i18n.language, 'https://caru.bbbprograms.org/seal/Confirmation/1596150491')
  const modalRef = useRef<HTMLDivElement>(null)

  return (
    <div
      id='modal-holder'
      className={modalClass}
      onClick={() => props.setModal(undefined)}
      onKeyDown={() => props.setModal(undefined)}
      role='presentation'
    >
      <div id='modal-wrapper' className='h-5/6' ref={modalRef}>
        {props.type === 'Caru' && <LeavingSiteModal modalRef={modalRef} intendedUrl={caruLink} setModal={props.setModal } />}
        {props.type === 'TermsOfUse' && <TermsOfUseModal modalRef={modalRef} setModal={props.setModal} />}
        {props.type === 'RedeemCodeInstructions' && <RedeemCodeInstructionsModal modalRef={modalRef} setModal={props.setModal} />}
      </div>
    </div>
  )
}

export default Modal
