import { useTranslation } from 'react-i18next'
import { latamCountryCodes } from '../../utils/utils'
import { useState } from 'react'

export const PocketInstructions = () => {
  const { t } = useTranslation()
  const basePocketGiftUrl = 'https://gift.pokemontcgpocket.com'
  const [pocketGiftUrl, setPocketGiftUrl] = useState('https://gift.pokemontcgpocket.com/en/')

  const region = window.sessionStorage.getItem('region')
  const currentRegion = region?.toLowerCase()
  if (currentRegion) {
    if (latamCountryCodes.includes(currentRegion)) {
      setPocketGiftUrl(`${basePocketGiftUrl}/es/`)
    } else if (currentRegion === 'fr') {
      setPocketGiftUrl(`${basePocketGiftUrl}/fr/`)
    } else if (currentRegion === 'de') {
      setPocketGiftUrl(`${basePocketGiftUrl}/de/`)
    } else if (currentRegion === 'it') {
      setPocketGiftUrl(`${basePocketGiftUrl}/it/`)
    } else if (currentRegion === 'br') {
      setPocketGiftUrl(`${basePocketGiftUrl}/pt-br/`)
    }
  }
  return (
    <div className='text-sm'>
      <ol className='list-decimal list-outside ml-4'>
        <li><a href={pocketGiftUrl} className='font-bold text-link'> {t('visit_web_here', 'Visit this website.')}</a></li>
        <li>{t('pocket_step_2', 'Enter your in-game support ID and code.')}</li>
        <li>{t('pocket_step_3', 'After successfully redeeming a code, claim your rewards in the Pokémon TCG Pocket app by tapping on the “Gift” icon.')}</li>
      </ol>
    </div>
  )
}
